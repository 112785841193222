@import "rsuite/dist/rsuite.css";

/* #root{ padding: 10px; } */

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
    background-color: #fff !important;
    background-color: rgb(28, 26, 28) !important;
    border-color: rgb(108, 108, 108) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #000000 !important;
}